import React from "react"
// import useKeyDown from "../../hooks/UseKeyDown"
import "./Popup.scss"

const Popup = ({ children, name, isOpen, bodyId, onCloseClicked, title }) => {
  // useKeyDown("Escape", () => onCloseClicked(), [])
  return (
    <div
      className={`popup ${name ? `popup--${name}` : ""} ${
        isOpen ? `popup--open` : ``
      }`}
    >
      <div
        className="popup__veil"
        onClick={() => onCloseClicked()}
        role="button"
        tabIndex="0"
        onKeyDown={() => onCloseClicked()}
        aria-label="close popup"
      />
      <div id={bodyId} className={`popup__body`}>
        <header className="popup__header">
          <h3 className="popup__title">{title}</h3>
          <button
            type="button"
            className="popup__close"
            onClick={() => onCloseClicked()}
          >
            <svg
              className="popup__close-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M18.94,2.94a1.5,1.5,0,0,1,2.12,2.12L14.12,12l6.94,6.94a1.5,1.5,0,0,1,.11,2l-.11.11a1.49,1.49,0,0,1-2.12,0h0L12,14.12,5.06,21.06a1.5,1.5,0,0,1-2,.1l-.11-.1a1.49,1.49,0,0,1,0-2.12h0L9.88,12,2.94,5.06a1.5,1.5,0,0,1-.1-2l.1-.11a1.49,1.49,0,0,1,2.12,0h0L12,9.88Z"
                fillRule="evenodd"
              />
            </svg>
          </button>
        </header>

        {children}
      </div>
    </div>
  )
}

Popup.defaultProps = {
  isOpen: false,
}

export default Popup
