export const isDev = process.env.NODE_ENV === 'development'

export const removeFields = <F extends string[], R extends Record<string, any>>(fields: string[], obj: Record<string, any>): R => {
  const s = new Set(fields)
  return Object.keys(obj)
    .filter(k => !s.has(k))
    .reduce((o, k) => {
        o[k] = obj[k]
        return o
    }, {}) as R;
}

export const convertStringBool = <R extends Record<string, 'Yes'|'No'>>(obj: R): {[K in keyof R]: boolean} => {
    return Object.fromEntries(
        Object.entries(obj)
            .map(([k, v]) => [
                k,
                v === 'Yes' ? true : v === 'No' ? false : v
            ])
    ) as any;
}

export const current_year = new Date().getFullYear();

export const is_external = (x: string) => x.startsWith('http://') || x.startsWith('https://');
