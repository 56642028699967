import React, { useContext } from "react"
import { Link } from "gatsby"
import { FooterMenu, Footer as StyledFooter, FooterCopyrights } from "./style"
import { PhiDispatchContext } from "../../context"
import HolyLogo from "../../images/holy.inline.svg"
import { current_year, is_external } from '../../utils/helpers'
import { page_names } from '../../constants'

function Newsletter() {
  const dispatch = useContext(PhiDispatchContext)

  return (
    <button
      style={{ padding: 0 }}
      className="link"
      onClick={() => dispatch({ type: "TOGGLE_EARLY_ACCESS_POPUP" })}
    >
      Newsletter
    </button>
  )
}

const FooterLinks = {
  Product: [
    {
      url: "/pricing",
      label: "Plans",
    },
    {
      url: "/updates",
      label: page_names.updates,
    },
    {
      url: "/terms-of-service",
      label: "Terms of Service",
    },
    {
      url: "/privacy-policy",
      label: "Privacy Policy",
    },
    {
      url: "https://phi.nolt.io/",
      label: "Suggest a Feature",
    },
  ],

  Learn: [
    {
      url: "/instructions",
      label: "Instructions",
    },
    {
      url: "/knowledge/learn",
      label: "Knowledge",
    },
  ],

  Company: [
    {
      url: "/contact",
      label: "Contact us",
    },
    {
      url: "/news",
      label: "News",
    },
    {
      render: () => <Newsletter />,
      label: "Newsletter",
    },
  ],

  "Follow Phi": [
    {
      url: "https://www.linkedin.com/company/phi-3d",
      label: "LinkedIn",
    },
    {
      url: "https://www.facebook.com/phi3d.modeler",
      label: "Facebook",
    },
    {
      url: "https://www.instagram.com/phi.3d",
      label: "Instagram",
    },
    {
      url: "https://www.tiktok.com/@phi.3d",
      label: "TikTok",
    },
    {
      url: "https://www.youtube.com/@phi3d",
      label: "YouTube",
    },
    {
      url: "https://discord.gg/jGj9k6ySen",
      label: "Discord",
    },
    // {
    //   url: "https://twitter.com/phi3d_modeler",
    //   label: "Twitter",
    // },
  ],
}

/** @param {{ url: String; label: string; render?: Function }} x */
function FooterListItem({ url, label, render }) {
    if (render)
        return <FooterMenu.ListItem key={label} title={label}>
            {render()}
        </FooterMenu.ListItem>
    else if (is_external(url))
        return <FooterMenu.ListItem key={label}>
            <a href={url} rel="noopener noreferrer" target="_blank" title={label}>
                {label}
            </a>
        </FooterMenu.ListItem>
    else
        return <FooterMenu.ListItem key={label}>
            <Link to={url} title={label}>{label}</Link>
        </FooterMenu.ListItem>
}

const Footer = ({ isHome }) => <StyledFooter as="footer" isHome={isHome}>
    <FooterMenu>
        {Object.entries(FooterLinks).map((menu) => <div key={menu[0]}>
            <FooterMenu.Title as="h4">{menu[0]}</FooterMenu.Title>
            <FooterMenu.List length={menu[1].length}>
                {menu[1].map(FooterListItem)}
            </FooterMenu.List>
        </div>)}
    </FooterMenu>
    <FooterCopyrights>
        <FooterCopyrights.Text>
            © {current_year} Phenometry P.C. branded by{" "}
        </FooterCopyrights.Text>
        <FooterCopyrights.Link
            href="https://holy.gd"
            rel="noopener noreferrer"
            target="_blank"
        >
            <HolyLogo />
        </FooterCopyrights.Link>
    </FooterCopyrights>
</StyledFooter>

export default Footer
