import { transitions } from "polished"
import styled from "styled-components"
import { Caption, Small } from "../../styles"
import { CheckElement } from "../../styles/form-elements"
// import { _size } from "./functions"

export const FieldError = styled(Small)`
  color: ${({ theme }) => theme.colors.greyLight};
  padding: 0.4rem 0;
`

export const Label = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

export const LabelWrapper = styled.label`
  display: flow-root;
`
export const LabelOptional = styled(Caption)`
  color: ${({ theme }) => theme.colors.grey};
`

export const CheckboxWrapper = styled(LabelWrapper)`
  display: grid;
  align-items: start;
  grid-template-columns: 1.8rem auto;
  grid-template-areas: "check label" ". error";
  cursor: pointer;
  gap: 0.5em 1em;

  input {
    grid-area: check;
  }

  ${CheckElement} {
    grid-area: check;
  }

  ${Label} {
    grid-area: label;
    margin-top: 0;
    margin-bottom: 0;

    ${Small} {
      color: ${({ theme }) => theme.colors.greyLight};
    }
  }

  ${FieldError} {
    grid-area: error;
    color: ${({ theme }) => theme.colors.white};
  }

  a {
    color: ${({ theme }) => theme.colors.white};
    // text-decoration: underline;
    ${transitions(["color"], ".2s ease")};

    &:hover {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`

export const SelectWrapper = styled.div`
  position: relative;

  svg {
    width: 0.75em;
    height: auto;
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    fill: ${({ theme }) => theme.colors.grey};
  }
`

export const Select = styled.select`
  -webkit-appearance: none;
  background: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.greyDark};
  caret-color: ${({ theme }) => theme.colors.brand};
  border-radius: 0.4rem;
  color: inherit;
  width: 100%;
  display: block;
  resize: none;
  padding: 1.2rem 1.2rem;

  &:focus,
  &:focus-within {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.offWhite};
    background: ${({ theme }) => theme.colors.offBlack};
  }

  &:-webkit-autofill {
    overflow: hidden;
    color: inherit !important;
    -webkit-box-shadow: 0 0 0 100px white inset;
  }
`

export const GroupWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;

  > * + * {
    margin-left: 1em;
  }
`

export const RadioWrapper = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  opacity: ${({ selected, theme }) =>
    selected ? 1 : theme.opacity.extraFaded};

  > div {
    margin-top: 0.15em;
  }

  > * {
    margin-bottom: 0;

    + * {
      margin-left: 0.5em;
    }
  }
`

export const Input = styled.input.attrs((props) => ({
  ...props,
  type: props.type,
}))`
  -webkit-appearance: none;
  background: ${({ theme }) => theme.colors.black};
  caret-color: ${({ theme }) => theme.colors.brand};
  border-radius: 0.4rem;
  color: inherit;
  width: 100%;
  display: block;
  resize: none;
  padding: 1.2rem 0.8rem;
  ${transitions(["background, border-color"], ".2s ease")};

  &:focus,
  &:focus-within {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.offWhite};
    background: ${({ theme }) => theme.colors.offBlack};
  }

  &:-webkit-autofill {
    overflow: hidden;
    color: inherit !important;
    -webkit-box-shadow: 0 0 0 100px white inset;
  }

  ${({ vhidden }) =>
    vhidden &&
    `
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      `}
`
