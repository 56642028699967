export const _size = (s) => `
width: ${s};
height: ${s};
`

export const gap = (g = ".5em") => `
  --gap: ${g};
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  > * {
    margin: var(--gap) 0 0 var(--gap);
  }
`

export const ar = (w = 16, h = 9, withChild = false) => `
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-top: calc((${h} / ${w}) * 100%);
  }

  ${withChild &&
  `
     > * {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      inset: 0;
    }
  `
  }
`


export const fluidRem = (min = "1.8rem", max = "3.2rem", minWidth = 375, maxWidth = 1280, rem = 10) => {
  let minSize = parseFloat(min)
  let maxSize = parseFloat(max)
  let minWidthRem = minWidth / rem
  let maxWidthRem = maxWidth / rem
  let sizeDiff = maxSize - minSize
  let widthDiff = maxWidthRem - minWidthRem
  let slope = sizeDiff / widthDiff
  let intersection = ((-1 * minWidthRem) * slope) + minSize

  return `clamp(${min}, ${intersection}rem + ${slope * 100}vw, ${max})`
}
