import React from 'react';

type Props = {
    children: JSX.Element | Array<JSX.Element>;
}

export function ClientSideOnly(props: Props) {
    const isClientSide = typeof window !== 'undefined';
    return isClientSide ? <>{props.children}</> : <div/>;
}
