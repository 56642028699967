import { transitions } from "polished"
import styled from "styled-components"
import { _size } from "./functions"

export const RadioCheckLabel = styled.label`
  display: ${({ block }) => (block ? `grid` : `inline-grid`)};
  grid-template-columns: max-content auto;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  color: var(--text-color);
  position: relative;
`

export const RadioElement = styled.div`
  ${_size("1.8rem")};
  border-radius: 1rem;
  border: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.colors.brand : theme.colors.grey};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${transitions(["border-color"], ".2s ease")}

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ checked, theme }) =>
      checked
        ? `translate(-50%, -50%) scale(1)`
        : `translate(-50%, -50%) scale(0)`};
    ${_size(".5rem")};
    border-radius: 0.25rem;
    background-color: ${({ checked, theme }) =>
      checked ? theme.colors.brand : theme.colors.grey};
    ${transitions(["transform"], ".2s ease")};
  }
`

export const CheckElement = styled.div`
  ${_size("1.8rem")};
  flex: 0 0 1.8rem;
  border-radius: 0.4rem;
  padding: 0.3rem;
  border: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.colors.brand : theme.colors.grey};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${transitions(["border-color"], ".2s ease")}

  svg {
    width: 100%;
    height: auto;
    fill: ${({ theme }) => theme.colors.brand};
    transform: ${({ checked, theme }) => (checked ? `scale(1)` : `scale(0)`)};
    ${transitions(["transform"], ".2s ease")}/* ${({ checked }) =>
      checked && `transform: scale(1);`} */
  }
`
