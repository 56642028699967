import React, { useState, useEffect } from "react"
import { Input } from "../FormElements/styles"
import Button from "../Button"
import Checkbox from "../knowledge/Checkbox"

const EarlyAccessForm = () => {
  const [isValid, setIsValid] = useState(false)
  const [emailValidity, setΕmailValidity] = useState(false)
  const [fnameValidity, setFnameValidity] = useState(false)
  const [lnameValidity, setLnameValidity] = useState(false)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    let arr = [emailValidity, fnameValidity, lnameValidity, checked]
    // console.log(arr)
    // console.log(arr.every(v => v === true))
    setIsValid(arr.every(v => v === true))
  }, [checked, emailValidity, fnameValidity, lnameValidity])

  const handleEmailBlur = e => {
    setΕmailValidity(e.target.validity.valid)
  }
  const handleFnameBlur = e => {
    setFnameValidity(e.target.validity.valid)
  }
  const handleLnamelBlur = e => {
    setLnameValidity(e.target.validity.valid)
  }

  return (
    <div id="mc_embed_signup">
      <form
        action="https://phi3d.us4.list-manage.com/subscribe/post?u=4025d45e2b0bec08696e2f065&amp;id=397fb45e24"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        rel="noopener noreferrer"
        noValidate
        autoComplete="nope"
      >
        <div id="mc_embed_signup_scroll">
          <div hidden=""><input type="hidden" name="tags" value="7872648"></input></div>
          <div className="mc-field-group">
            <Input
              type="email"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              onBlur={handleEmailBlur}
              placeholder="Email"
              required
              autoComplete="nope"
            />
            {/* <label htmlFor="mce-EMAIL">
               <span>Email Address</span> <span assName="asterisk">*</span>
             </label> */}
          </div>
          <div className="mc-field-group">
            {/* <label htmlFor="mce-FNAME">
               First Name <span className="asterisk">*</span>
             </label> */}
            <Input
              type="text"
              name="FNAME"
              className="required"
              id="mce-FNAME"
              onBlur={handleFnameBlur}
              placeholder="First name"
              required
              autoComplete="nope"
            />
          </div>
          <div className="mc-field-group">
            {/* <label htmlFor="mce-LNAME">
               Last Name <span className="asterisk">*</span>
             </label> */}
            <Input
              type="text"
              name="LNAME"
              className="required"
              id="mce-LNAME"
              onBlur={handleLnamelBlur}
              placeholder="Last name"
              required
              autoComplete="nope"
            />
          </div>
          <div className="mc-field-group">
            {/* <label htmlFor="mce-MMERGE7">
               <span>Company</span>{" "}
               <span className="optional">optional</span>
             </label> */}
            <Input
              type="text"
              name="MMERGE7"
              className=""
              id="mce-MMERGE7"
              placeholder="Company (optional)"
            />
          </div>
          <div
            id="mergeRow-gdpr"
            className="mergeRow gdpr-mergeRow content__gdprBlock -field-group"
          >
            <div className="content__gdpr">
              {/* <h2>Communication Permissions</h2>
              <p>Check the box to enable our communication via email:</p> */}
              <fieldset
                className="mc_fieldset gdprRequired mc-field-group"
                name="interestgroup_field"
              >
                <Checkbox
                  label="Check to enable our email communication"
                  id="gdpr_36131"
                  name="gdpr[36131]"
                  checked={checked}
                  onChange={checked => setChecked(checked)}
                />
              </fieldset>
              {/* <p>
                You can unsubscribe at any time by clicking the link in e footer
                of our emails. For information about our privacy practices,
                please visit our website.
              </p> */}
            </div>
          </div>
          <Button className="subscribe" type="submit" disabled={!isValid}>
            Subscribe
          </Button>
          {/* <div className="clear">
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button"
              disabled={isValid}
            />
          </div> */}
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_4025d45e2b0bec08696e2f065_397fb45e24"
              tabIndex="-1"
            />
          </div>
        </div>
        <div className="content__gdprLegal">
          <p>
            We use Mailchimp as our marketing platform. By clicking above to subscribe, you acknowledge that your information will be transferred
            to Mailchimp for processing.{" "}
            <a
              href="https://mailchimp.com/legal/"
              target="_blank"
              rel="noopener noreferrer"
              title="Mailchimp's privacy policy"
            >
              Learn more about Mailchimp's privacy practices here.
            </a>
          </p>
        </div>
      </form>
    </div>
  )
}

export default EarlyAccessForm
