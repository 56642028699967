import React from 'react';
import Helmet from 'react-helmet';

export function GoogleAnalytics(props: { tag: string }) {
    return <Helmet>
        <script async
            src={`https://www.googletagmanager.com/gtag/js?id=${props.tag}`}
        />
        <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${props.tag}');
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            });
        `}</script>
    </Helmet>;
}
