import { useCookie } from './use-cookie.ts';

export type IGtag =
    (type: 'consent', action: 'update', settings: Partial<{
        ad_user_data: 'granted' | 'denied';
        ad_personalization: 'granted' | 'denied';
        ad_storage: 'granted' | 'denied';
        analytics_storage: 'granted' | 'denied';
    }>) => void;

type Props = {
    children: (provisions: ICookieConsentProvisions) => JSX.Element;
}

type ICookieConsentProvisions = {
    consent: boolean;
    shouldAsk: boolean;
    updateConsent: (consent: boolean) => void;
}

const CONSENT_KEY = 'phi-consent';
const CONSENT_TIMESTAMP_KEY = 'phi-consent-at';
const SIX_MONTHS_MS = 6 * 30 * 24 * 60 * 60 * 1000;

type Consent = 'grant' | 'deny';

export function CookieConsentProvider(props: Props): JSX.Element {
    const [cookie, setCookie] = useCookie();
    const permission = cookie[CONSENT_KEY] as undefined | Consent;
    const time = parseFloat(cookie[CONSENT_TIMESTAMP_KEY] || '0');
    const dt = Date.now() - time;

    const consent = permission === 'grant' && dt < SIX_MONTHS_MS;

    const shouldAsk = dt >= SIX_MONTHS_MS;

    function updateConsent(consent: boolean) {
        setCookie(CONSENT_KEY, consent ? 'grant' : 'deny', true);
        setCookie(CONSENT_TIMESTAMP_KEY, Date.now().toString(), true);

        // @ts-ignore
        const gtag: IGtag = window.gtag || (() => undefined);
        gtag('consent', 'update', {
            ad_user_data: consent ? 'granted' : 'denied',
            ad_personalization: consent ? 'granted' : 'denied',
            ad_storage: consent ? 'granted' : 'denied',
            analytics_storage: consent ? 'granted' : 'denied',
        });
    }

    const provisions: ICookieConsentProvisions = {
        consent,
        shouldAsk,
        updateConsent,
    };

    return props.children(provisions);
}
