import { useState } from 'react';

function parseCookie(cookie: string): Record<string, string> {
    return cookie.split('; ')
        .map(x => x.split('='))
        .reduce((cookies, [k, v]) => {
            cookies[k] = decodeURIComponent(v);
            return cookies;
        }, {});
}

function rootDomain(domain: string) {
    const parts = domain.split('.');
    if (parts.length === 2) return domain;
    else return parts.slice(-2).join('.')
}

type IUseCookie = [
    Record<string, string>,
    (key: string, value: string, subdomains?: boolean) => void,
];

export function useCookie(D = document): IUseCookie {
    const [cookie, setCookie] = useState(parseCookie(D.cookie));

    function publicSetCookie(key: string, value: string, subdomains = false) {
        let cookie = `${key}=${encodeURIComponent(value)}`;
        if (subdomains)
            cookie += `;path=/;domain=${rootDomain(D.location.hostname)}`;
        D.cookie = cookie;
        setCookie(parseCookie(D.cookie));
    }

    return [cookie, publicSetCookie];
}
