import React from 'react';
import Helmet from 'react-helmet';

export function GoogleTagManagerHead(props: { tag: string }) {
    return <Helmet>
        <script>{`
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            const first_script = document.getElementsByTagName('script')[0],
            const new_script = document.createElement('script');
            new_script.async = true;
            new_script.src = 'https://www.googletagmanager.com/gtm.js?id=${props.tag}';
            first_script.parentNode.insertBefore(new_script, first_script);
        `}</script>
    </Helmet>;
}

export function GoogleTagManagerIframe(props: { tag: string }) {
    return <noscript>
        <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${props.tag}`}
            height='0'
            width='0'
            className='invisible hidden'
        />
    </noscript>;
}
