import { transitions } from "polished"
import styled from "styled-components"
import { Small, Smaller } from "../../styles/typography"
import Container from "../Container"

export const Footer = styled(Container)`
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  background: ${({ isHome, theme }) =>
    !isHome
      ? theme.colors.offBlack
      : `linear-gradient(90deg, #444444, #0a0a0a)`};
`

export const FooterMenu = styled.nav`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 3.2rem;
  padding: 5.6rem 0;
`

const FooterMenuTitle = styled(Small)`
  color: ${({ theme }) => theme.colors.greyLight};
  margin-bottom: 1.6rem;
`

const FooterMenuList = styled.ul`
  ${({ length }) => length > 6 && `columns: 5.6rem 2`};
`
const FooterMenuListItem = styled.li`
  & + & {
    margin-top: 1.6rem;
  }

  a,
  .link {
    font-weight: ${({ fontWeight, theme }) =>
      fontWeight ? fontWeight : theme.fontWeights.normal};
    font-size: 1.4rem;
    line-height: 1.42;
    color: ${({ theme }) => theme.colors.white};
    ${transitions(["color"], ".2s ease")};
    white-space: nowrap;

    &:hover {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`

FooterMenu.Title = FooterMenuTitle
FooterMenu.List = FooterMenuList
FooterMenu.ListItem = FooterMenuListItem

export const FooterCopyrights = styled.div``
const FooterCopyrightsText = styled(Smaller)`
  color: ${({ theme }) => theme.colors.grey};
`

const FooterCopyrightsLink = styled.a`
  color: ${({ theme }) => theme.colors.grey};
  ${transitions(["color"], ".2s ease")};

  &:hover {
    color: ${({ theme }) => theme.colors.offWhite};
  }

  svg {
    width: 2.8rem;
    height: auto;
    fill: currentColor;
  }
`

FooterCopyrights.Text = FooterCopyrightsText
FooterCopyrights.Link = FooterCopyrightsLink
