import { choice } from './string';

const chars = 'abcdefghijklmnopqrstuvwxyz';
export function random_id(len = 16) {
    let r = '';
    for (let i = 0; i < len; i++)
        r += choice(chars);
    return r;
}

export const classNames = (xs: Record<string, boolean>) =>
    Object.entries(xs)
        .filter(x => x[1])
        .map(x => x[0])
        .join(' ');
