import { hideVisually, transitions } from "polished"
import React from "react"
import styled from "styled-components"
import { Small } from "../../styles"
import { RadioCheckLabel, CheckElement } from "../../styles/form-elements"
import CheckIcon from "../../images/check.inline.svg"

const Wrapper = styled(RadioCheckLabel)`
  --check-color: ${({ checked, theme }) =>
    checked ? theme.colors.brand : theme.colors.grey};
  --text-color: ${({ theme }) => theme.colors.offWhite};

  &:hover {
    --check-color: ${({ checked, theme }) =>
      checked ? theme.colors.brandLight : theme.colors.greyLight};
    --text-color: ${({ theme }) => theme.colors.white};
  }

  + label {
    margin-top: 1rem;
  }

  span {
    ${transitions(["color"], ".2s ease")}
  }

  small {
    color: var(---text-color);
  }
`

function Checkbox({ label, name, checked, block = true, onChange, ...props }) {
  return (
    <Wrapper className="radio" checked={checked} block={block}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        css={hideVisually()}
        onChange={(e) => onChange(e.target.checked)}
        {...props}
      />
      <CheckElement checked={checked}>
        <CheckIcon />
      </CheckElement>
      <Small>{label}</Small>
    </Wrapper>
  )
}

export default Checkbox
