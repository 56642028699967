export const onshape_link = 'https://appstore.onshape.com/apps/Design%20&%20Documentation/BI4NF3BZAEQXBXOERCYO6NSHJ5Z62TB6ZI472VA=/description';

export const google_tag = 'G-LBT2851J8K';

export const google_tag_manager_id = 'GTM-WMB7B829';

export const page_names = {
    updates: 'Updates',
}

/** the id for the stripe pricing table */
// export const stripe_pricing_table = process.env.STRIPE_PRICING_TABLE ?? 'dummy';
// Hacky fix
export const stripe_pricing_table = 'prctbl_1OgSD7EUQTMplWzvgOV61g0H';

/** the stripe publishable key */
// export const stripe_public = process.env.STRIPE_PUBLIC ?? 'dummy';
// Hacky fix
export const stripe_public = 'pk_live_51OWkn2EUQTMplWzvEQQmcqqAWXJPH4eIdfwEWifxvIAIe7XTzieZjO6mIckmQvwbj2sQdG3ZdOGeV1em4jtkOhAh00AanHjKF4'
