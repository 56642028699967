import './index.scss';
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { transitions } from "polished"
import { _size } from "../../styles/functions"
import { classNames } from '../../utils/html';

const getHeight = (h) => {
  switch (h) {
    case "small":
      return `1`
    case "large":
      return `2`
    default:
      return `1.25`
  }
}
const getFontSize = (s) => {
  switch (s) {
    case "small":
      return `1.4rem`
    case "large":
      return `2rem`
    default:
      return `1.6rem`
  }
}

const getDisplay = (p) => {
  const { active, block, icon } = p
  if (block && icon) return `flex`
  if (block) return `block`
  if (icon || active) return `inline-flex`
  return `inline-block`
}

// @ts-ignore
const StyledButton = styled.button`
  font-family: inherit;
  font-size: ${({ size }) => getFontSize(size)};
  line-height: ${({ size }) => getHeight(size)};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  display: ${(props) => getDisplay(props)};
  gap: 0.5em;
  grid-template-columns: max-content 1fr;
  vertical-align: middle;
  place-items: center;
  place-content: center;
  padding: 0.75em 1.5em;
  border: 1px solid transparent;
  border-radius: 0.25em;
  white-space: nowrap;
  cursor: pointer;
  ${transitions(
    ["color", "background-color", "border-color"],
    ".2s ease-in-out"
  )}
  ${({ disabled, theme }) =>
    disabled &&
    `
    opacity: ${theme.opacity.muted};
    pointer-events: none;
  `}

  ${({ active }) => `
    ${Active} {
      display: inline;
    }
  `}

  svg {
    ${_size(".85em")}
    fill: currentColor;
  }

  span {
    user-select: none;
  }
`

// @ts-ignore
const Active = styled.span`
  display: none;
  flex: 0 0 0.5em;
  ${_size(".5em")}
  border-radius: .3em;
  background-color: ${({ theme }) => theme.colors.brand};
`

// @ts-ignore
const StyledLink = styled(Link)`
  &.active {
    ${({ block }) =>
      block ? "display: flex; width: 100%;" : "display: inline-grid;"};

    ${Active} {
      display: inline;
    }
  }
`

type Props = {
  active?: undefined | boolean;
  children: Array<JSX.Element | string> | string | JSX.Element;
  disabled?: undefined | boolean | 'false';
  href?: undefined | string;
  icon?: unknown;
  iconLeft?: undefined | boolean;
  iconRight?: undefined | boolean;
  partiallyActive?: undefined | boolean;
  size?: undefined | 'default' | 'small' | 'large';
  target?: undefined | string;
  title?: undefined | string;
  to?: undefined | string;
  variant?: undefined | 'primary' | 'secondary' | 'ghost' | 'shine';
  wrapping?: undefined | boolean;
  extraClass?: undefined | string;
  onClick?: undefined | ((x: MouseEvent) => void);
  onMouseOver?: undefined | ((x: MouseEvent) => void);
  rel?: undefined | string;
  tabIndex?: undefined | number;
}

const Button = (props: Props) => {
  const {
    active,
    children,
    disabled = "false",
    href,
    icon,
    iconLeft = true,
    iconRight,
    partiallyActive,
    size = "default",
    title,
    to,
    variant = "primary",
    wrapping = false,
    extraClass = '',
    tabIndex = undefined
  } = props
  const renderedElement = href ? 'a' : to ? StyledLink : 'button';
  return (
    <StyledButton
      className={classNames({
        button: true,
        wrapping: wrapping,
        primary: variant === 'primary',
        secondary: variant === 'secondary',
        ghost: variant === 'ghost',
        shine: variant === 'shine',
      }) + ' ' + extraClass}
      {...props}
      as={renderedElement}
    >
      {icon ? (
        <>
          {partiallyActive || active ? <Active className='active' /> : null}
          {iconLeft && icon}
          {children && <span>{children}</span>}
          {iconRight && icon}
        </>
      ) : (
        <>
          {partiallyActive || active ? <Active className='active' /> : null}
          {children && <span>{children}</span>}
        </>
      )}
    </StyledButton>
  )
}

export default Button
