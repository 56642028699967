import { Link as GatsbyLink } from "gatsby"
import React, { useContext, useEffect, useRef, useState } from "react"
import { PhiDispatchContext, PhiStateContext } from "../../context"
import Logo from "../Logo/Logo"
import Menu from "../../images/menu.inline.svg"
import Close from "../../images/close.inline.svg"
import styled from "styled-components"
import Container from "../Container"
import { _size, gap } from "../../styles/functions"
import { transitions } from "polished"
import Button from "../Button"
import { page_names } from '../../constants'
import { useMount } from '../../utils/hooks'
import { init } from '../../utils/string'

const Header = styled.header`
  --header-height: ${({ headerHeight }) => headerHeight}px;
  top: 0px;
  width: 100%;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndexes.header};
  padding: 2.4rem 0;
  ${({ scrollPos, theme }) =>
    scrollPos > 48 &&
    `background-image: linear-gradient(
      ${theme.colors.black} 70%,
      transparent
    );`}
`

const NavContainer = styled(Container)`
  display: flex;
  align-items: center;

  > a {
    position: relative;
    z-index: ${({ theme }) => theme.zIndexes.mobileMenu};
    flex: 0 0 120px;
    display: block;

    @media (max-width: 1110px) {
      flex: 0 0 auto;
    }

    svg {
      width: 120px;
      height: auto;
    }
  }
`

const NavContainerMobile = styled(Container)`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.black};
  top: 0;
  right: 0;
  height: -webkit-fill-available;
  width: 100%;
  transform: translateX(100%);
  z-index: ${({ theme }) => theme.zIndexes.mobileMenu};
  padding: var(--header-height) 3.2rem 2.4rem;
  pointer-events: none;
  ${transitions(["transform"], ".3s ease-in-out")}
  ${({ open }) => open && `transform: translateX(0); pointer-events: auto;`}
  overflow-y: auto;

  @media (min-width: 1111px) {
    display: none;
  }
`

const NavListDesktop = styled.ul`
  flex: 1;
  display: none;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  ${gap("1em")}

  @media (min-width: 1111px) {
    display: flex;
  }
`

const MobileMenuToggler = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  z-index: ${({ theme }) => theme.zIndexes.mobileMenu};
  margin-left: auto;
  ${_size("4rem")};

  @media (min-width: 1111px) {
    display: none;
  }

  svg {
    ${_size("1.75em")}
    fill: ${({ theme }) => theme.colors.white};
  }
`

/* @ts-ignore */
const MobileLink = styled((props) => <GatsbyLink {...props} />)`
  font-size: clamp(1.7em, ((43 / 768) * 100vw), 2.4em);
  font-weight: ${({ theme }) => theme.fontWeights.light};
  padding: 0.5em 0;
  display: block;
  letter-spacing: -0.02em;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  ${transitions(["color", "opacity"], ".2s ease")}

  &:last-child {
    border-radius: 0.15em;
    padding: 0.5em;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    background-color: ${({ theme }) => theme.colors.brand};
    color: ${({ theme }) => theme.colors.black};
  }

  + a {
    border-top: 1px solid ${({ theme }) => theme.colors.greyDark};
  }

  &.active {
    position: relative;

    span {
      display: inline-block;

      &:before {
        content: "• ";
        color: ${({ theme }) => theme.colors.brand};
      }
    }
  }
`

const strip_final_slash = (x: string) => x.endsWith('/') ? init(x) : x

const NavBar = () => {
  const [pathname, setPathname] = useState('');
  const dispatch = useContext(PhiDispatchContext)
  const { mobileMenu } = useContext(PhiStateContext)
  const [scrollPos, setScrollPos] = useState(0)
  const headerRef = useRef(null)
  const [headerHeight, setHeaderHeight] = useState(0)

  useMount(() => {
    if (typeof window !== 'undefined')
        setPathname(strip_final_slash(location.pathname));

    let timeoutId: null | ReturnType<typeof setTimeout> = null

    const resizeListener = () => {
        if (timeoutId)
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            if (window.innerWidth > 1111)
                dispatch({ type: "CLOSE_MOBILE_MENU" })
        }, 100)
    }

    window.addEventListener("scroll", scrolling)
    window.addEventListener("resize", resizeListener)

    return () => {
        window.removeEventListener("scroll", scrolling)
        window.removeEventListener("resize", resizeListener)
    }
  })

  useEffect(() => {
    const h = headerRef.current as null | HTMLElement;
    const doc = document.documentElement
    if (!h) return
    setHeaderHeight(h.getBoundingClientRect().height)
    doc.style.setProperty(
      "--header-height",
      `${h.getBoundingClientRect().height}px`
    )
  }, [headerRef])

  const scrolling = () => {
    if (!window) return
    setScrollPos(window.scrollY)
  }

  return (
    <Header scrollPos={scrollPos} ref={headerRef} headerHeight={headerHeight}>
      <NavContainerMobile open={mobileMenu} as="nav">
        <MobileLink
          to="/knowledge"
          title="Lean Surface 3D Modeling"
          activeClassName="active"
          partiallyActive={true}
          onClick={() => dispatch({ type: "TOGGLE_MOBILE_MENU" })}
        >
          <span>Learn</span>
        </MobileLink>

        <MobileLink
          to="/pricing"
          title="Choose your Phi plan"
          activeClassName="active"
          partiallyActive={true}
          onClick={() => dispatch({ type: "TOGGLE_MOBILE_MENU" })}
        >
          <span>Plans</span>
        </MobileLink>

        <MobileLink
          to="/updates"
          title="Phi Updates"
          activeClassName="active"
          partiallyActive={true}
          onClick={() => dispatch({ type: "TOGGLE_MOBILE_MENU" })}
        >
          <span>{page_names.updates}</span>
        </MobileLink>

        {/* <MobileLink
          to="/instructions"
          title="Take your first steps with Phi"
          activeClassName="active"
          partiallyActive={true}
          onClick={() => dispatch({ type: "TOGGLE_MOBILE_MENU" })}
        >
          <span>Instructions</span>
        </MobileLink> */}

        <MobileLink
          to="/news"
          title="Company & Product News"
          activeClassName="active"
          partiallyActive={true}
          onClick={() => dispatch({ type: "TOGGLE_MOBILE_MENU" })}
        >
          <span>News</span>
        </MobileLink>

        <MobileLink
          to="/contact"
          title="We are always here to help"
          activeClassName="active"
          partiallyActive={true}
          onClick={() => dispatch({ type: "TOGGLE_MOBILE_MENU" })}
        >
          <span>Contact</span>
        </MobileLink>

        <MobileLink
          href="https://app.phi3d.com/"
          title="Try Phi now!"
          activeClassName="active"
          partiallyActive={true}
          onClick={() => dispatch({ type: "TOGGLE_MOBILE_MENU" })}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span>Access Phi Now</span>
        </MobileLink>
      </NavContainerMobile>

      <NavContainer as="nav">
        {/* @ts-ignore */}
        <GatsbyLink to="/" title="Design amazing 3D Models">
          <Logo />
        </GatsbyLink>

        <NavListDesktop>
          <li>
            <Button
              active={pathname === '/knowledge/learn'}
              to="/knowledge/learn"
              partiallyActive={true}
              variant="shine"
              title="Lean Surface 3D Modeling"
            >
              Learn
            </Button>
          </li>

          <li>
            <Button
              active={pathname === '/pricing'}
              to="/pricing"
              variant="shine"
              partiallyActive={true}
              title="Choose your Phi plan"
            >
              Plans
            </Button>
          </li>

          <li>
            <Button
              active={pathname === '/updates'}
              to="/updates"
              variant="shine"
              partiallyActive={true}
              title="Phi Updates"
            >
              {page_names.updates}
            </Button>
          </li>

          {/* <li>
            <Button
              active={pathname === '/instructions'}
              to="/instructions"
              variant="shine"
              partiallyActive={true}
              title="Take your first steps with Phi"
            >
              Instructions
            </Button>
          </li> */}

          <li>
            <Button
              active={pathname === '/news'}
              to="/news"
              variant="shine"
              partiallyActive={true}
              title="Company & Product News"
            >
              News
            </Button>
          </li>

          <li>
            <Button
              active={pathname === '/contact'}
              to="/contact"
              variant="shine"
              partiallyActive={true}
              title="We are always here to help"
            >
              Contact
            </Button>
          </li>

          <li>
            <Button
              href="https://app.phi3d.com/"
              variant="primary"
              partiallyActive={true}
              rel="noopener noreferrer"
              target="_blank"
              title="Try Phi now!"
            >
              Access Phi Now
            </Button>
          </li>
        </NavListDesktop>

        <MobileMenuToggler
          onClick={() => dispatch({ type: "TOGGLE_MOBILE_MENU" })}
        >
          {mobileMenu ? <Close /> : <Menu />}
        </MobileMenuToggler>
      </NavContainer>
    </Header>
  )
}

export default NavBar

/* <CTA
  onClick={() => dispatch({ type: "TOGGLE_EARLY_ACCESS_POPUP" })}
  title="Join our Beta"
  withArrow={false}
/> */
