import React from "react"

const Logo = () => (
  <svg width="149" height="70" viewBox="0 0 1072 494">
    <defs>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="172.1404"
        y1="448.0602"
        x2="172.1404"
        y2="73.2202"
        gradientTransform="matrix(1 0 0 -1 0 494)"
      >
        <stop offset="0" stopColor="#FFFFFF" />
        <stop offset="1" stopColor="#4D4D4D" />
      </linearGradient>
      <path
        fill="url(#SVGID_1_)"
        d="M247.2,395.6V0.6c0,0-52.6-1.1-96.3,19.5C62.7,57.6,0.8,145.1,0.8,247c0,136.1,110.3,246.4,246.4,246.4
		c33.1,0,65.8-6.6,96.3-19.5C305.1,486.5,247.2,463.1,247.2,395.6z"
      />

      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="370.4154"
        y1="84.8903"
        x2="370.4154"
        y2="405.5802"
        gradientTransform="matrix(1 0 0 -1 0 494)"
      >
        <stop offset="0" stopColor="#FFFFFF" />
        <stop offset="1" stopColor="#4D4D4D" />
      </linearGradient>
    </defs>
    <path
      fill="#FFFFFF"
      d="M646.4,134.9h85c15.5,0,28.2,2.1,38.2,6.3c10,4.2,17.9,9.5,23.7,16c5.7,6.3,9.8,13.7,12.1,21.8
		c4.5,15.2,4.5,31.5,0,46.7c-2.3,8.1-6.4,15.6-12.1,21.8c-5.8,6.5-13.7,11.8-23.7,16s-22.7,6.3-38.2,6.4h-52.2v106.5h-32.8V134.9z
		 M679.1,241.3h43c6.3,0,12.7-0.5,18.9-1.5c5.9-0.9,11.5-2.8,16.7-5.6c4.9-2.8,9-6.8,11.8-11.8c3-5.1,4.4-11.8,4.4-20
		c0-8.2-1.5-14.9-4.4-20c-2.8-4.9-6.9-8.9-11.8-11.7c-5.2-2.8-10.9-4.7-16.7-5.6c-6.3-1-12.6-1.5-18.9-1.5h-43V241.3z"
    />
    <path
      fill="#FFFFFF"
      d="M840.8,118.5h30.7v121.1h0.7c3.9-8.6,10.6-15.6,20.1-21c9.6-5.4,20.6-8,33.1-8c7.6,0,15.1,1.2,22.3,3.6
		c7,2.3,13.4,6.1,18.8,11.1c5.6,5.4,10,12,12.8,19.3c3.2,7.8,4.8,17.1,4.8,27.8v104.1h-30.7v-95.5c0-7.5-1-13.9-3.1-19.3
		c-1.7-4.9-4.5-9.3-8.2-13c-3.3-3.2-7.4-5.7-11.8-7.2c-4.5-1.5-9.1-2.2-13.8-2.2c-6-0.1-12.1,1-17.7,3.1c-5.5,2.1-10.4,5.4-14.3,9.7
		c-4.4,4.9-7.6,10.7-9.6,16.9c-2.3,6.8-3.4,14.9-3.4,24.2v83.2h-30.7V118.5z"
    />
    <path
      fill="#FFFFFF"
      d="M1025.8,156.7c-0.1-12.5,10-22.8,22.5-22.9s22.8,10,22.9,22.5s-10,22.8-22.5,22.9c-6.1,0.1-11.9-2.3-16.2-6.7
		C1028.1,168.5,1025.7,162.7,1025.8,156.7z M1033,214.7h30.7v161.7H1033V214.7z"
    />

    <path
      fill="url(#SVGID_1_)"
      d="M247.2,395.6V0.6c0,0-52.6-1.1-96.3,19.5C62.7,57.6,0.8,145.1,0.8,247c0,136.1,110.3,246.4,246.4,246.4
		c33.1,0,65.8-6.6,96.3-19.5C305.1,486.5,247.2,463.1,247.2,395.6z"
    />

    <path
      fill="url(#SVGID_2_)"
      d="M247.2,0.6v32.8c72,0.6,129.8,59.5,129.2,131.5c-0.6,71.1-58.1,128.6-129.2,129.2v101.5
		c0,67.5,57.9,90.9,96.3,78.3c88.2-37.5,150.1-125,150.1-226.9C493.6,110.9,383.3,0.6,247.2,0.6z"
    />
  </svg>
)

export default Logo
