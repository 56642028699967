import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import NavBar from '../components/NavBar/NavBar'
import { PhiDispatchContext } from '../context'
import { PhiStateContext } from '../context'
import Popup from '../components/Popup/Popup'
import EarlyAccessForm from '../components/EarlyAccessForm/EarlyAccessForm'
import '../scss/index.scss';
import Footer from '../components/Footer/Footer'
import usePage from '../hooks/usePage'
import { GoogleAnalytics } from './components/google-analytics'
import { google_tag, google_tag_manager_id } from '../constants'
import { GoogleTagManagerHead, GoogleTagManagerIframe } from './components/google-tag-manager'
import { CookieConsentModal } from '../features/cookie-consent/cookie-consent-modal'
import { CookieConsentProvider } from '../features/cookie-consent/cookie-consent-provider'
import { ClientSideOnly } from '../components/ClientSideOnly'

const Layout = ({ children }) => {
    const dispatch: any = useContext(PhiDispatchContext)
    const { mobileMenu, earlyAccessPopup }: any = useContext(PhiStateContext)
    const [isHome] = usePage()

    useEffect(() => {
        const body = document.body
        body.style.overflow = mobileMenu ? 'hidden' : 'auto'
    }, [mobileMenu])

    return <>
        <GoogleAnalytics tag={google_tag} />
        <GoogleTagManagerHead tag={google_tag_manager_id} />
        <GoogleTagManagerIframe tag={google_tag_manager_id} />
        <ClientSideOnly>
            <CookieConsentProvider>{
                ({ shouldAsk, updateConsent }) => <>
                    <CookieConsentModal
                        shouldAsk={shouldAsk}
                        onConsent={updateConsent}
                    />
                </>}
            </CookieConsentProvider>
        </ClientSideOnly>
        <NavBar />
        {children}
        <Footer isHome={isHome} />
        <Popup
            bodyId={undefined}
            name='early-access'
            isOpen={earlyAccessPopup}
            onCloseClicked={() => dispatch({ type: 'TOGGLE_EARLY_ACCESS_POPUP' })}
            title='Get Product Updates'
        >
            <EarlyAccessForm />
        </Popup>
    </>
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
