import styled from "styled-components"

const handleBleed = (bleed, columnWidth) => {
  switch (bleed) {
    case "right":
      return `0 0 0 max(2.4rem, 50vw - ${columnWidth}px / 2)`
    case "left":
      return `0 max(2.4rem, 50vw - ${columnWidth}px / 2) 0 0`
    default:
      return `0 max(2.4rem, 50vw - ${columnWidth}px / 2)`
  }
}

const Container = styled.div`
  padding: ${({ bleed, columnWidth = 1280 }) =>
    handleBleed(bleed, columnWidth)};
`

export default Container
