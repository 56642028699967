import { useEffect, useState } from "react"
import { useLocation } from "@reach/router"

const createPathName = (pathname) => {
  return pathname.slice(1).split("/").join("--")
}

export default function usePage() {
  const [isHome, setIsHome] = useState(false)
  const [pageName, setPageName] = useState("")
  const location = useLocation()

  useEffect(() => {
    location.pathname === "/" && setIsHome(true)
    setPageName(() => createPathName(location.pathname))
  }, [])

  return [isHome, pageName]
}
