import { Link } from 'gatsby';
import React from 'react';
import Button from '../../components/Button/index.tsx';

type Props = {
    shouldAsk: boolean;
    onConsent: (consent: boolean) => void;
}

export function CookieConsentModal(props: Props) {
    const classes = [
        'animation-delay-long',
        'animation-duration-medium',
        'animation-fill-forwards',
        'bg-grey-semi-dark',
        'bottom-0',
        'desktop:flex-row',
        'fixed',
        'flex-col',
        'gap-8',
        'items-center',
        'justify-center',
        'left-0',
        'p-8',
        'right-0',
        'slid-below',
        'slideup',
        'text-offwhite z-10',
        'z-modal',
    ];
    classes.push(props.shouldAsk ? 'flex' : 'hidden');

    return <aside className={classes.join(' ')} aria-label='Cookie Banner' role='region'>
        <div>
            We use cookies to deliver and maintain our services,
            protect against fraud, and measure user engagement
            with new features to enhance the quality of our services. <span className='bold text-white no-word-wrap'>
                <Link to='/privacy-policy' title='Privacy policy'>Learn more</Link>
            </span>
        </div>

        <div className='flex gap-8 flex-nowrap'>
            <Button tabIndex={0} onClick={() => props.onConsent(true)}>Allow all</Button>
            <Button tabIndex={0} variant='secondary' onClick={() => props.onConsent(false)}>Necessary only</Button>
        </div>
    </aside>
}
